import React, { PropsWithChildren } from 'react'
import { motion, MotionValue, useTransform } from 'framer-motion'
import { featureIcon, featureStyle, featureText } from './styles'

type FeatureProps = {
  range: number[]
  title: string
  scrollY: MotionValue<number>
}

export const Feature = ({ range, scrollY, title, children }: PropsWithChildren<FeatureProps>) => {
  const opacity = useTransform(scrollY, range, [0, 1])
  const y = useTransform(scrollY, range, [-5, 0])
  return (
    <motion.div style={{ opacity, y }} className={featureStyle}>
      <>
        <div className={featureIcon}>{children}</div>
        <span className={featureText}>{title}</span>
      </>
    </motion.div>
  )
}
