import React, { PropsWithChildren } from 'react'
import { motion, MotionValue, useTransform } from 'framer-motion'
import { animationPoints } from '.'
import { containerParent, motionParent } from './styles'

type OldImageProps = {
  endPoint: number
  scrollY: MotionValue<number>
}

export const Container = ({ scrollY, endPoint, children }: PropsWithChildren<OldImageProps>) => {
  const transform = useTransform(
    scrollY,
    [animationPoints.flip.start * endPoint, animationPoints.flip.end * endPoint],
    [
      'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(43deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)',
      'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)',
    ]
  )
  return (
    <motion.div
      className={containerParent}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <motion.div className={motionParent} style={{ transform }}>
        {children}
      </motion.div>
    </motion.div>
  )
}
