export const parent = "p1nuqav8";
export const originalImageStyles = "o1asw6lg";
export const enhancingParent = "e1wfb9ha";
export const enhancingText = "e1d43wt7";
export const dot = "dhf39w1";
export const enhancedImageParent = "ee0ec0u";
export const enhancedImageStyles = "e1otoc6e";
export const enhancementBorder = "ezzbozy";
export const motionParent = "m1sae9ty";
export const containerParent = "cet5raf";

require("./styles.linaria.module.css!=!../../../node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js?cacheProvider=!./styles.ts");