import React from 'react'

import {
  AiArtifactsRemoval,
  AiUpscaling,
  AiObjectDetection,
  BrightnessCorrection,
  ColorCorrection,
  ContrastCorrection,
  PdfEnhancement,
  RedEyeCorrection,
  SharpnessCorrection,
} from '@components/ui/icon'

import Box from '@components/ui/layout/Box'
import { iconLabel } from './styles'

const backgrounds = {
  0: '/assets/usp-background-1.png',
  1: '/assets/usp-background-10.png',
  2: '/assets/usp-background-11.png',
  3: '/assets/usp-background-2.png',
  4: '/assets/usp-background-3.png',
  5: '/assets/usp-background-4.png',
  6: '/assets/usp-background-5.png',
  7: '/assets/usp-background-8.png',
  8: '/assets/usp-background-9.png',
}

const icons = {
  0: AiObjectDetection,
  1: AiArtifactsRemoval,
  2: AiUpscaling,
  3: ColorCorrection,
  4: ContrastCorrection,
  5: BrightnessCorrection,
  6: SharpnessCorrection,
  7: RedEyeCorrection,
  8: PdfEnhancement,
}

export const IconWithBackground = ({ backgroundNr, iconNr }) => {
  const Icon = icons[iconNr]
  return (
    <Box
      alignItems="center"
      as="div"
      className={iconLabel}
      display="flex"
      justifyContent="center"
      style={{ background: `url(${backgrounds[backgroundNr]}) center/cover no-repeat` }}
    >
      <Icon />
    </Box>
  )
}
