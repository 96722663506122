import { parse } from '@config/theme';
export const container = parse({
  pb: {
    _: '8',
    md: '12'
  },
  mb: {
    _: '11',
    sm: '18'
  },
  alignItems: {
    _: 'flex-start',
    sm: 'center'
  },
  display: 'flex',
  justifyContent: 'space-between'
}, "cjvuwok");
export const text = parse({
  mb: {
    _: '6',
    sm: '0'
  }
});

require("./styles.linaria.module.css!=!../../../node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js?cacheProvider=!./styles.ts");