import React, { FC, useState, useRef, useEffect } from 'react'
import { Container } from './Container'
import { EnhancedImage } from './EnhancedImage'
import { Enhancing } from './Enhancing'
import { Features } from './Features'
import { Image } from './Image'
import * as styles from './styles'
import { useImage } from '@lib/hooks'
import { useViewportScroll, AnimatePresence } from 'framer-motion'
import { HomeSingleton } from '__generated__/sanity'
import { urlFor } from '@lib/sanity/url-for'

type InteractiveEnhancerProps = HomeSingleton['fields']['hero']

export const animationPoints = {
  flip: {
    start: 0,
    end: 0.3,
  },
  enhancedImage: {
    start: 0,
    end: 0.8,
  },
  features: {
    start: 0.08,
    end: 0.085,
  },
  pulse: {
    start: 0.14,
    end: 0.18,
  },
}

export const InteractiveEnhancer: FC<InteractiveEnhancerProps> = ({
  untouchedImage,
  enhancedImage,
}) => {
  const untouchedUrl = urlFor(untouchedImage).url()
  const enhancedUrl = urlFor(enhancedImage).url()

  const [isVisible, setIsVisible] = useState(false)
  const [untouchedStatus] = useImage(untouchedUrl)
  const [enhancedStatus] = useImage(enhancedUrl)
  const { scrollY } = useViewportScroll()
  const width = useRef<number>()
  const [end, setEnd] = useState(0)
  const ref = useRef<HTMLDivElement>()

  useEffect(() => {
    calculate()

    window.addEventListener('resize', calculate)

    return () => {
      window.removeEventListener('resize', calculate)
    }

    function calculate() {
      if (window.innerWidth !== width.current) {
        setIsVisible(false)
        setEnd(
          ref.current.getBoundingClientRect().top +
            ref.current.getBoundingClientRect().height +
            window.pageYOffset
        )
        setIsVisible(true)
        width.current = window.innerWidth
      }
    }
  }, [])

  return (
    <>
      <div className={styles.parent} ref={ref}>
        <AnimatePresence>
          {isVisible && untouchedStatus === 'loaded' && enhancedStatus === 'loaded' && (
            <Container endPoint={end} scrollY={scrollY}>
              <Image image={untouchedUrl} alt="" />
              <EnhancedImage image={enhancedUrl} endPoint={end} scrollY={scrollY} />
              <Features endPoint={end} scrollY={scrollY} />
              <Enhancing endPoint={end} scrollY={scrollY} />
            </Container>
          )}
        </AnimatePresence>
      </div>
    </>
  )
}
