import Box from '@components/ui/layout/Box'
import { parseAll, Theme } from '@config/theme'
import { cx } from '@linaria/core'
import React, { FC, HTMLAttributes } from 'react'
import { filterProps, ThemeSystemProps } from 'theme-system'
import { card, content } from './styles'

const backgrounds = {
  0: '/assets/card-background-1.png',
  1: '/assets/card-background-2.png',
  2: '/assets/card-background-3.png',
}

type Props = HTMLAttributes<HTMLDivElement> &
  Pick<ThemeSystemProps<Theme>, 'm' | 'my' | 'mx' | 'mt' | 'mr' | 'mb' | 'ml'> & {
    backgroundNr: number
  }

export const Card: FC<Props> = ({ children, className, backgroundNr = 0, ...rest }) => {
  return (
    <Box
      className={cx(parseAll(rest), className, card)}
      style={{ background: `url(${backgrounds[backgroundNr]}) center/cover no-repeat` }}
      {...filterProps(rest)}
    >
      <Box className={content}>{children}</Box>
    </Box>
  )
}
