import { RichTextBlock } from '@components/rich-text-block';
import Box from '@components/ui/layout/Box';
import Container from '@components/ui/layout/Container';
import { Heading, Text } from '@components/ui/typography';
import React, { PropsWithChildren } from 'react';
import { SanityBlock, SanityKeyed } from 'sanity-codegen';
const container = "cb9785w";
const textContainer = "tr4f07x";
const titleStyling = "t2vk50r";
const intro = "i3u1lf4";
const childrenContainer = "c1hkoi43";
type Props = {
  title: string;
  subtitle?: string;
  introSection: Array<SanityKeyed<SanityBlock>>;
  id?: string;
};
export const Section = ({
  title,
  subtitle,
  introSection,
  children,
  id
}: PropsWithChildren<Props>) => {
  return <Container className={container} mb={{
    _: '11',
    sm: '18'
  }} id={id}>
      <Box className={textContainer}>
        <Heading as="h2" variant="h2" mb={{
        _: '2',
        sm: '4',
        md: '6'
      }} className={titleStyling}>
          {title}
        </Heading>
        {subtitle && <Heading as="h4" variant="h4" color="shade400" mb={{
        _: '3',
        sm: '4',
        md: '6'
      }}>
            {subtitle}
          </Heading>}
        <Text variant="bodyLarge" mb={{
        _: '10',
        sm: '12',
        md: '13'
      }} className={intro}>
          <RichTextBlock content={introSection} variant="special" />
        </Text>
      </Box>
      <Box mx="auto" className={childrenContainer}>
        {children}
      </Box>
    </Container>;
};

require("./Section.linaria.module.css!=!../../node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js?cacheProvider=!./Section.tsx");