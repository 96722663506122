import React, { FC } from 'react';
import { Document } from '@components/ui/icon';
import { Heading, Text } from '@components/ui/typography';
import Box from '@components/ui/layout/Box';
const container = "c7yosu2";
const background = "b7wng17";
const titles = "tcgz4nx";
const linkStyling = "luivvih";
type Props = {
  title: string;
  link: string;
};
export const DownloadResource: FC<Props> = ({
  title,
  link
}) => {
  return <Box display="flex" mb={{
    _: '6',
    xl: '4'
  }} className={container}>
      <Box alignItems="center" className={background} display="flex" justifyContent="center" mr={{
      _: '4',
      sm: '6'
    }}>
        <Document />
      </Box>
      <div>
        <span className={titles}>
          <Heading as="h6" variant="h6" mb="1">
            {title}
          </Heading>
        </span>
        <a href={link}>
          <Text variant="bodySmall" mb="2" color="purpleWeb" className={linkStyling}>
            Download
          </Text>
        </a>
      </div>
    </Box>;
};

require("./index.linaria.module.css!=!../../node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js?cacheProvider=!./index.tsx");