import React, { FC } from 'react'
import { hideOnDesktopS, showOnDesktopS } from './styles'

export const IEUpscaling: FC = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="48"
        height="48"
        fill="none"
        viewBox="0 0 48 48"
        className={hideOnDesktopS}
      >
        <path
          stroke="currentColor"
          strokeWidth="2"
          d="M6 10.5V9a3 3 0 013-3h30a3 3 0 013 3v30.75A2.25 2.25 0 0139.75 42v0H37.5M6 19.5V18a3 3 0 013-3h21a3 3 0 013 3v21.75A2.25 2.25 0 0130.75 42v0H28.5M15 24H9a3 3 0 00-3 3v12a3 3 0 003 3h12a3 3 0 003-3V27a3 3 0 00-3-3h-6z"
        ></path>
      </svg>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="48"
        height="48"
        fill="none"
        viewBox="0 0 48 48"
        className={showOnDesktopS}
      >
        <path
          stroke="currentColor"
          strokeWidth="2"
          d="M9 12.75V12a3 3 0 013-3h24a3 3 0 013 3v25.125C39 38.16 38.16 39 37.125 39v0H35.25M9 20.25v-.75a3 3 0 013-3h16.5a3 3 0 013 3v17.625c0 1.035-.84 1.875-1.875 1.875v0H27.75M16.5 24H12a3 3 0 00-3 3v9a3 3 0 003 3h9a3 3 0 003-3v-9a3 3 0 00-3-3h-4.5z"
        ></path>
      </svg>
    </>
  )
}
