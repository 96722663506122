import { DownloadResource } from '@components/download-resource'
import { Faq } from '@components/faq'
import Box from '@components/ui/layout/Box'
import { Heading, Text } from '@components/ui/typography'
import React, { FC, useState } from 'react'
import { HomeSingleton } from '__generated__/sanity'
import * as styles from './styles'

type Props = {
  questions: HomeSingleton['fields']['faq']
  resources: HomeSingleton['fields']['resources']
}

export const FaqSection: FC<Props> = ({ questions, resources }) => {
  const [showAll, setShowAll] = useState(false)
  const visibleQuestions = showAll ? questions.faqBlocks : questions.faqBlocks.slice(0, 4)

  return (
    <section id="faq">
      <Box className={styles.container} mx="auto">
        <hr className={styles.divider} />
        <Box display="grid" className={styles.grid}>
          <Box className={styles.faqs}>
            <Heading as="h5" variant="h5" mb={{ _: '4', sm: '6' }}>
              Frequently Asked Questions (FAQ)
            </Heading>
            <ul>
              {visibleQuestions.map((item) => (
                <li key={`faq${item.faqQuestion}`}>
                  <Faq question={item.faqQuestion} answer={item.faqAnswer} />
                </li>
              ))}
              {questions.faqBlocks.length > 4 && !showAll && (
                <>
                  <Text
                    as="button"
                    color="purpleWeb"
                    ml={{ _: '4', sm: '6' }}
                    onClick={() => setShowAll(true)}
                    variant="bodyRegular"
                  >
                    Show more ({questions.faqBlocks.length - 4})
                  </Text>
                </>
              )}
            </ul>
          </Box>
          {resources.resourceBlocks && resources.resourceBlocks.length > 0 && (
            <Box className={styles.resourcesContainer}>
              <Heading as="h5" variant="h5" mb={{ _: '4', sm: '6' }}>
                Resources
              </Heading>
              <ul className={styles.resourcesParent}>
                {resources.resourceBlocks.map((item, i) => (
                  <li key={`resource${i}`}>
                    <DownloadResource title={item.resourceTitle} link={item.resourceLink} />
                  </li>
                ))}
              </ul>
            </Box>
          )}
        </Box>
      </Box>
    </section>
  )
}
