import React, { FC } from 'react'
import { hideOnDesktopS, showOnDesktopS } from './styles'

export const IESharpnessCorrection: FC = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="48"
        height="48"
        fill="none"
        viewBox="0 0 48 48"
        className={hideOnDesktopS}
      >
        <circle cx="24" cy="24" r="17" stroke="currentColor" strokeWidth="2"></circle>
        <path
          stroke="currentColor"
          strokeWidth="2"
          d="M24 16.917l7.792 12.041H16.209L24 16.917z"
        ></path>
      </svg>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="48"
        height="48"
        fill="none"
        viewBox="0 0 48 48"
        className={showOnDesktopS}
      >
        <circle cx="24" cy="24" r="15" stroke="currentColor" strokeWidth="2"></circle>
        <path
          stroke="currentColor"
          strokeWidth="2"
          d="M24 17.75l6.875 10.625h-13.75L24 17.75z"
        ></path>
      </svg>
    </>
  )
}
