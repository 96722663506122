import { Application, CommandLine, File, Folder, Plugin } from '@components/ui/icon'
import Box from '@components/ui/layout/Box'
import { Heading, Text } from '@components/ui/typography'
import { MenuButton } from '@components/_home/utlities-section/MenuButton'
import React, { FC } from 'react'
import * as styles from './styles'

const icons = {
  application: Application,
  folder: Folder,
  commandLine: CommandLine,
  file: File,
  plugin: Plugin,
}

export type UtilityItemType = {
  title: string
  subtitle: string
  text: string
  logo: 'application' | 'folder' | 'commandLine' | 'file' | 'plugin'
  button1_title?: string
  button1_link_1_title?: string
  button1_link_1_url: string
  button1_link_2_title?: string
  button1_link_2_url: string
  button2_title?: string
  button2_link_1_title?: string
  button2_link_1_url: string
  button2_link_2_title?: string
  button2_link_2_url: string
}

type Props = {
  item: UtilityItemType
  fscAction?: string
  fscItemPathValues?: string[]
}

export const UtilityItem: FC<Props> = ({ item, fscAction, fscItemPathValues }) => {
  const Icon = icons[`${item.logo}`]
  return (
    <Box display="flex" mb={{ _: '8', xl: '12' }} className={styles.utilityItemContainer}>
      <Box
        alignItems="center"
        className={styles.background}
        display="flex"
        justifyContent="center"
        mr={{ _: '4', sm: '6' }}
      >
        <Icon />
      </Box>
      <div className={styles.copy}>
        <span className={styles.titles}>
          <Heading as="h6" variant="h6" mb="1">
            {item.title}
          </Heading>
          <Text as="em" color="shade400" variant="bodySmall" mx="2" className={styles.separator}>
            ●
          </Text>
          <Text variant="bodySmall" color="shade400" mb="1">
            {item.subtitle}
          </Text>
        </span>
        <Text variant="bodySmall" mb="2">
          {item.text}
        </Text>
        <Box display="flex" flexWrap="wrap" className={styles.menuButtons}>
          {item.button1_title && (
            <MenuButton
              title={item.button1_title}
              link1title={item.button1_link_1_title}
              link1url={item.button1_link_1_url}
              link2title={item.button1_link_2_title}
              link2url={item.button1_link_2_url}
              offset="left"
            />
          )}
          {item.button2_title && (
            <MenuButton
              title={item.button2_title}
              link1title={item.button2_link_1_title}
              link1url={item.button2_link_1_url}
              link2title={item.button2_link_2_title}
              link2url={item.button2_link_2_url}
              offset="right"
              {...{ fscAction, fscItemPathValues }}
            />
          )}
        </Box>
      </div>
    </Box>
  )
}
