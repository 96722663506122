import { GetInTouch } from '@components/get-in-touch'
import { useGetInTouchContext } from '@components/get-in-touch/Context'
import { Page } from '@components/page'
import { Seo } from '@components/Seo'
import { ComparisonSliders } from '@components/_home/comparison-sliders'
import { FaqSection } from '@components/_home/faq-section'
import { IntegrationSection } from '@components/_home/integration-section'
import { InteractiveEnhancer } from '@components/_home/interactive-enhancer'
import { Intro } from '@components/_home/intro'
import { TestimonialsSection } from '@components/_home/testimonials'
import { TryCloud } from '@components/_home/try-cloud'
import { UsedBySection } from '@components/_home/used-by-section'
import { Usps } from '@components/_home/usps'
import { UtiliesSection } from '@components/_home/utlities-section'
import { getSanityClient } from '@lib/sanity/client'
import { ImageWithMetadata } from '@lib/sanity/types'
import { GetStaticProps } from 'next'
import React, { FC } from 'react'
import { HomeSingleton } from '__generated__/sanity'

export type ComparisonSlider = {
  nonEnhanced: ImageWithMetadata
  enhanced: ImageWithMetadata
}

type HomeProps = {
  pageContent: HomeSingleton
  comparisonSliders: ComparisonSlider[]
}

const Home: FC<HomeProps> = ({ pageContent, comparisonSliders }) => {
  const { isVisible, setIsVisible } = useGetInTouchContext()
  return (
    <Page>
      <Seo data={pageContent.fields.seo} />
      {isVisible && <GetInTouch />}
      <Intro {...pageContent.fields.hero} />
      <InteractiveEnhancer {...pageContent.fields.hero} />
      <TryCloud />
      <Usps {...pageContent.fields.usps} />
      <IntegrationSection {...pageContent.fields.integrations} setShowOverlay={setIsVisible} />
      <UsedBySection {...pageContent.fields.usedBy} />
      <ComparisonSliders items={comparisonSliders} />
      <TestimonialsSection {...pageContent.fields['testimonials']} setShowOverlay={setIsVisible} />
      <FaqSection
        questions={pageContent.fields['faq']}
        resources={pageContent.fields['resources']}
      />
      <UtiliesSection utilities={pageContent.fields['utilities']} />
    </Page>
  )
}

export const getStaticProps: GetStaticProps<HomeProps> = async ({ preview = false }) => {
  const sanity = getSanityClient(preview)

  const query = `*[_type == "home-singleton"] {
    ...,
    "comparisonSliders": fields.comparisonSliders.comparisonSliderItems[]{
      "nonEnhanced": nonEnhancedImage.asset->{
        url,
        metadata {
          dimensions
        }
      },
      "enhanced": enhancedImage.asset->{
        url,
        metadata {
          dimensions
        }
      }
    }
  }`
  
  const queryResult = await sanity.fetch(query)
  const draft = queryResult.find((item) => item._id.startsWith('drafts.'))
  const content = preview ? draft || queryResult[0] : queryResult[0]

  return {
    props: {
      pageContent: content as HomeSingleton,
      comparisonSliders: content.comparisonSliders as ComparisonSlider[],
    },
  }
}

export default Home
