import { Button } from '@components/ui/Button'
import SmallContainer from '@components/ui/layout/SmallContainer'
import { Heading, Text } from '@components/ui/typography'
import React, { FC } from 'react'
import * as styles from './styles'

export const TryCloud: FC = () => {
  return (
    <SmallContainer className={styles.container}>
      <div className={styles.text}>
        <Heading variant="h4" as="h3" mb="2">
          Better photos. Give it a try.
        </Heading>
        <Heading variant="h5" as="h4" color="shade500" mb="0">
          Viesus Cloud is here.
        </Heading>
      </div>
      <Button as="link" href="https://viesus.cloud" target="blank" showIcon>
        <Text variant="buttonLabelRegular" as="span">
          Give it a try
        </Text>
      </Button>
    </SmallContainer>
  )
}
