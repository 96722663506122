import { Button } from '@components/ui/Button'
import { Heading, Text } from '@components/ui/typography'
import { motion } from 'framer-motion'
import React, { Dispatch, FC, SetStateAction, useState } from 'react'
import { HomeSingleton } from '__generated__/sanity'
import { FadeInChild } from '../FadeInChild'
import { Section } from '../Section'
import { staggerAnimation, StaggerChildren } from '../StaggerChildren'
import { Card } from './Card'
import { cardsContainer, textStyles } from './styles'

type Props = HomeSingleton['fields']['integrations'] & {
  setShowOverlay: Dispatch<SetStateAction<boolean>>
}

export const IntegrationSection: FC<Props> = ({
  integrationsTitle,
  integrationsBlocks,
  integrationsIntro,
  setShowOverlay,
}) => {
  const [startStagger, setStartStagger] = useState(false)

  return (
    <FadeInChild handleElementIsInView={() => setStartStagger(true)}>
      <Section title={integrationsTitle} introSection={integrationsIntro}>
        <StaggerChildren as="ul" className={cardsContainer} {...{ startStagger }}>
          {integrationsBlocks.map(
            (
              { integrationsBlockTitle, integrationsBlockDescription, integrationsBlockSubtitle },
              i
            ) => (
              <motion.li key={integrationsBlockTitle} variants={staggerAnimation}>
                <Card backgroundNr={i} mb={{ _: '6', sm: '8', md: '0' }}>
                  <Heading as="h5" variant="h5" mb="4">
                    {integrationsBlockTitle}
                  </Heading>
                  <Text
                    as="p"
                    variant="bodySmall"
                    mb={{ _: '6', sm: '4', md: '8', xl: '12' }}
                    className={textStyles}
                  >
                    {integrationsBlockDescription}
                  </Text>
                  <Button
                    as="button"
                    showIcon
                    width="100%"
                    mb="4"
                    my={{ xl: 'auto' }}
                    onClick={() => setShowOverlay(true)}
                  >
                    <Text variant="buttonLabelRegular" as="span">
                      Get in touch
                    </Text>
                  </Button>
                  <Heading
                    as="h6"
                    variant="h6"
                    my={{ xl: 'auto' }}
                    mx={{ _: 'auto', sm: '0', md: 'auto', xl: '0' }}
                    color="shade500"
                  >
                    {integrationsBlockSubtitle}
                  </Heading>
                </Card>
              </motion.li>
            )
          )}
        </StaggerChildren>
      </Section>
    </FadeInChild>
  )
}
