import { RichTextBlock } from '@components/rich-text-block'
import { Heading, Text } from '@components/ui/typography'
import React, { FC, useEffect, useState } from 'react'
import { HomeSingleton } from '__generated__/sanity'
import * as styles from './styles'

type IntroProps = HomeSingleton['fields']['hero']

export const Intro: FC<IntroProps> = ({ heroTitle, heroDescription }) => {
  const [isMounted, setIsMounted] = useState(false)

  useEffect(() => {
    setIsMounted(true)
  }, [])

  return (
    <div className={styles.container}>
      <Heading
        variant="h1"
        as="h1"
        mb={{ _: '4', sm: '8' }}
        className={styles.text}
        data-visibility={isMounted ? 'visible' : 'hidden'}
      >
        {heroTitle}
      </Heading>
      <Text
        variant="bodyLarge"
        as="h2"
        color="shade500"
        className={styles.text}
        data-visibility={isMounted ? 'visible' : 'hidden'}
        data-delay="true"
      >
        <RichTextBlock content={heroDescription} variant="special" />
      </Text>
    </div>
  )
}
