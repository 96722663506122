import React, { FC, HTMLAttributes } from 'react'
import { cx } from '@linaria/core'
import { parseAll } from '@config/theme'
import { filterProps } from 'theme-system'
import { IconWithBackground } from './IconWithBackground'
import { usp } from './styles'

type UspProps = HTMLAttributes<HTMLLIElement> & {
  as?: React.ElementType
  iconNr: number
  backgroundNr: number
}

export const Usp: FC<UspProps> = ({ backgroundNr, children, className, iconNr, ...rest }) => {
  return (
    <div className={cx(parseAll(rest), className, usp)} {...filterProps(rest)}>
      <IconWithBackground {...{ backgroundNr, iconNr }} />
      <div>{children}</div>
    </div>
  )
}
