import Box from '@components/ui/layout/Box'
import { Heading } from '@components/ui/typography'
import { motion } from 'framer-motion'
import React, { FC } from 'react'
import { HomeSingleton } from '__generated__/sanity'
import { staggerAnimation } from '../StaggerChildren'
import * as styles from './styles'
import { UtilityItem } from './UtilityItem'

type Props = {
  utilities: HomeSingleton['fields']['utilities']
}

export const UtiliesSection: FC<Props> = ({ utilities }) => {
  return (
    <Box className={styles.container}>
      <hr className={styles.divider} />
      <Heading as="h5" variant="h5" mb={{ _: '4', sm: '6' }}>
        Viesus™ Stand-alone Applications
      </Heading>
      <ul className={styles.utilitiesStyling}>
        {utilities.utilityBlocks.map((item, i) => {
          const hasButtonOne = item.utilityDropdownButtons && item.utilityDropdownButtons[0]
          const hasButtonTwo = hasButtonOne && hasButtonOne && item.utilityDropdownButtons[1]
          return (
            <motion.li key={`utilityItem${i}`} variants={staggerAnimation}>
              <UtilityItem
                item={{
                  title: item.utilityTitle,
                  subtitle: item.utilitySubtitle,
                  text: item.utilityText,
                  logo: item.utilityLogo,
                  button1_title: hasButtonOne
                    ? item.utilityDropdownButtons?.[0]?.utilityDropdownButtonTitle
                    : '',
                  button1_link_1_title: hasButtonOne
                    ? item.utilityDropdownButtons?.[0]?.utilityDropdownButton1Title
                    : '',
                  button1_link_1_url: hasButtonOne
                    ? item.utilityDropdownButtons?.[0]?.utilityDropdownButton1Link
                    : '',
                  button1_link_2_title: hasButtonOne
                    ? item.utilityDropdownButtons?.[0]?.utilityDropdownButton2Title
                    : '',
                  button1_link_2_url: hasButtonOne
                    ? item.utilityDropdownButtons?.[0]?.utilityDropdownButton2Link
                    : '',
                  button2_title: hasButtonTwo
                    ? item.utilityDropdownButtons?.[1]?.utilityDropdownButtonTitle
                    : '',
                  button2_link_1_title: hasButtonTwo
                    ? item.utilityDropdownButtons?.[1]?.utilityDropdownButton1Title
                    : '',
                  button2_link_1_url: hasButtonTwo
                    ? item.utilityDropdownButtons?.[1]?.utilityDropdownButton1Link
                    : '',
                  button2_link_2_title: hasButtonTwo
                    ? item.utilityDropdownButtons?.[1]?.utilityDropdownButton2Title
                    : '',
                  button2_link_2_url: hasButtonTwo
                    ? item.utilityDropdownButtons?.[1]?.utilityDropdownButton2Link
                    : '',
                }}
              />
            </motion.li>
          )
        })}
      </ul>
    </Box>
  )
}
