import React, { FC } from 'react'
import { MotionValue, useTransform, motion } from 'framer-motion'
import { animationPoints } from '.'
import { enhancingParent, enhancingText, dot } from './styles'

type EnhancingProps = {
  scrollY: MotionValue<number>
  endPoint: number
}

export const Enhancing: FC<EnhancingProps> = ({ scrollY, endPoint }) => {
  const start = animationPoints.pulse.start * endPoint
  const end = animationPoints.pulse.end * endPoint
  const opacity = useTransform(scrollY, [start, end], [0, 1])
  const y = useTransform(scrollY, [start, end], [-5, 0])
  return (
    <motion.div className={enhancingParent} style={{ opacity, y }}>
      <span className={enhancingText}>Enhancing</span>
      <span className={dot} />
    </motion.div>
  )
}
