import React, { FC } from 'react'
import { hideOnDesktopS, showOnDesktopS } from './styles'

export const IEContrastCorrection: FC = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="48"
        height="48"
        fill="none"
        viewBox="0 0 48 48"
        className={hideOnDesktopS}
      >
        <path
          stroke="currentColor"
          strokeWidth="2"
          d="M24 40c8.837 0 16-7.163 16-16S32.837 8 24 8a15.902 15.902 0 00-8 2.14 16.075 16.075 0 00-4 3.277A15.94 15.94 0 008 24a15.94 15.94 0 004 10.583 16.075 16.075 0 004 3.276A15.901 15.901 0 0024 40z"
        ></path>
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M16 10.14A15.902 15.902 0 0124 8v15.2h-4.8v1.6H24V40a15.901 15.901 0 01-8-2.14 16.075 16.075 0 01-4-3.277A15.94 15.94 0 018 24a15.94 15.94 0 014-10.583 16.075 16.075 0 014-3.277z"
          clipRule="evenodd"
        ></path>
        <path fill="currentColor" d="M24 23.2H28.8V24.8H24z"></path>
      </svg>
      <svg
        width="48"
        height="48"
        viewBox="0 0 48 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={showOnDesktopS}
      >
        <path
          d="M24.5 37C31.9558 37 38 30.9558 38 23.5C38 16.0442 31.9558 10 24.5 10C23.3346 10 22.2037 10.1477 21.125 10.4253C19.9276 10.7335 18.7946 11.2018 17.75 11.8061C16.4796 12.5409 15.3401 13.4769 14.375 14.5703C12.2745 16.9502 11 20.0762 11 23.5C11 26.9238 12.2745 30.0498 14.375 32.4297C15.3401 33.5231 16.4796 34.4591 17.75 35.1939C18.7946 35.7982 19.9276 36.2665 21.125 36.5747C22.2037 36.8523 23.3346 37 24.5 37Z"
          stroke="currentColor"
          strokeWidth="2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.75 11.8061C18.7946 11.2018 19.9276 10.7335 21.125 10.4253C22.2037 10.1477 23.3346 10 24.5 10V37C23.3346 37 22.2037 36.8523 21.125 36.5747C19.9276 36.2665 18.7946 35.7982 17.75 35.1939C16.4796 34.4591 15.3401 33.5231 14.375 32.4297C12.2745 30.0498 11 26.9238 11 23.5C11 20.0762 12.2745 16.9502 14.375 14.5703C15.3401 13.4769 16.4796 12.5409 17.75 11.8061ZM24.5 22.825H20.45V24.175H24.5V22.825Z"
          fill="currentColor"
        />
        <rect x="24.5" y="22.825" width="4.05" height="1.35" fill="currentColor" />
      </svg>
    </>
  )
}
