import React, { FC } from 'react'
import { originalImageStyles } from './styles'

type ImageProps = {
  image: string
  alt?: string
}

export const Image: FC<ImageProps> = ({ image }) => {
  return (
    <div
      className={originalImageStyles}
      style={{
        backgroundImage: `url(${image})`,
      }}
    />
  )
}
