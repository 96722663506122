import React, { FC } from 'react'
import { hideOnDesktopS, showOnDesktopS } from './styles'

export const IEAiObjectDetection: FC = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="48"
        height="48"
        fill="none"
        viewBox="0 0 48 48"
        className={hideOnDesktopS}
      >
        <path
          stroke="currentColor"
          strokeWidth="2"
          d="M14.625 6H9a3 3 0 00-3 3v6m26.813-9H39a3 3 0 013 3v6M6 33v6a3 3 0 003 3h6m18 0h6a3 3 0 003-3v-6"
        ></path>
        <circle cx="24" cy="24" r="8" stroke="currentColor" strokeWidth="2"></circle>
        <path stroke="currentColor" strokeWidth="2" d="M27.75 24a3.75 3.75 0 11-7.5 0"></path>
        <path stroke="currentColor" d="M14 25.5L15 25.5"></path>
      </svg>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="48"
        height="48"
        fill="none"
        viewBox="0 0 48 48"
        className={showOnDesktopS}
      >
        <path
          stroke="currentColor"
          strokeWidth="2"
          d="M16.188 9H12a3 3 0 00-3 3v4.5M31.344 9H36a3 3 0 013 3v4.5m-30 15V36a3 3 0 003 3h4.5m15 0H36a3 3 0 003-3v-4.5"
        ></path>
        <circle cx="24" cy="24" r="6.5" stroke="currentColor" strokeWidth="2"></circle>
        <path stroke="currentColor" strokeWidth="2" d="M27.125 24a3.125 3.125 0 01-6.25 0"></path>
      </svg>
    </>
  )
}
