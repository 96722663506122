import Image from '@components/ui/image'
import { motion, MotionValue, useTransform } from 'framer-motion'
import React, { FC } from 'react'
import { slider, line } from './styles'

type SlidingProps = {
  scrollY: MotionValue<number>
  start: number
  end: number
  placeholder: string
  sources: string[]
}

export const Sliding: FC<SlidingProps> = ({ scrollY, start, end, placeholder, sources }) => {
  const startingPoints = [0, -20, -30, -60]
  const height = useTransform(
    scrollY,
    [start, end],
    [
      `calc(${startingPoints[Math.floor(Math.random() * startingPoints.length)]}% + 0px)`,
      'calc(100% + 0px)',
    ]
  )
  return (
    <motion.div className={slider} style={{ height }}>
      <Image
        placeholderSrc={placeholder}
        sources={sources}
        height={['100%']}
        width={['100%']}
        position="absolute"
        objectPosition="top"
        alt=""
      />
      <span className={line} />
    </motion.div>
  )
}
