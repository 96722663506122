import React, { FC } from 'react'
import { hideOnDesktopS, showOnDesktopS } from './styles'

export const IEColorCorrection: FC = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="48"
        height="48"
        fill="none"
        viewBox="0 0 48 48"
        className={hideOnDesktopS}
      >
        <path
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M23.99 6l10.26 10.547c5.664 5.82 5.667 15.26.006 21.083-2.834 2.916-6.55 4.372-10.265 4.37m0-36L13.75 16.547l-.007.007a15.014 15.014 0 00-4.14 8.757 15.41 15.41 0 00.117 4.38M23.99 6v9m0 27v-9m0 9a14.184 14.184 0 01-6.963-1.832M23.99 24L12.355 35.978M23.991 24v-9m0 9v9m-11.636 2.978a14.925 14.925 0 002.1 2.338 14.46 14.46 0 002.573 1.852m-4.673-4.19a14.907 14.907 0 01-1.58-2.763 15.176 15.176 0 01-1.054-3.525M23.99 15L9.72 29.69M23.99 33l-6.963 7.168"
        ></path>
      </svg>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="48"
        height="48"
        fill="none"
        viewBox="0 0 48 48"
        className={showOnDesktopS}
      >
        <path
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M23.992 7l9.55 9.961c5.275 5.497 5.277 14.412.007 19.912-2.638 2.753-6.098 4.13-9.557 4.127m0-34l-9.535 9.961-.006.007c-2.223 2.32-3.508 5.248-3.855 8.27a14.763 14.763 0 00.11 4.136M23.991 7v8.5m0 25.5v-8.5m0 8.5a13.029 13.029 0 01-6.483-1.73M23.992 24L13.158 35.313M23.992 24v-8.5m0 8.5v8.5m-10.834 2.813a14.055 14.055 0 001.956 2.207 13.464 13.464 0 002.395 1.75m-4.35-3.957a14.128 14.128 0 01-1.472-2.61c-.462-1.071-.79-2.19-.982-3.329M23.992 15.5L10.705 29.374M23.992 32.5l-6.483 6.77"
        ></path>
      </svg>
    </>
  )
}
