export const container = "c1frre81";
export const divider = "d1kepyfr";
export const utilitiesStyling = "u1bizz2b";
export const utilityItemContainer = "u15rpf19";
export const background = "be2uhjd";
export const titles = "t25wx6h";
export const separator = "suk6d2r";
export const copy = "czki2kd";
export const menuButtons = "m1g3c4ke";

require("./styles.linaria.module.css!=!../../../node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js?cacheProvider=!./styles.ts");