import React, { FC } from 'react'
import { hideOnDesktopS, showOnDesktopS } from './styles'

export const IEBrightnessCorrection: FC = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="48"
        height="48"
        fill="none"
        viewBox="0 0 48 48"
        className={hideOnDesktopS}
      >
        <path
          stroke="currentColor"
          strokeWidth="2"
          d="M35.963 24c0 6.607-5.356 11.963-11.963 11.963-6.607 0-11.963-5.356-11.963-11.963m23.926 0c0-6.607-5.356-11.963-11.963-11.963-6.607 0-11.963 5.356-11.963 11.963m23.926 0H12.037M24 9.222V5M13.55 13.55l-2.985-2.985M34.45 13.55l2.985-2.985M38.778 24H43M24 43v-4.222M37.435 37.435l-2.986-2.986M10.565 37.435l2.986-2.986M5 24h4.222"
        ></path>
      </svg>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="48"
        height="48"
        fill="none"
        viewBox="0 0 48 48"
        className={showOnDesktopS}
      >
        <path
          stroke="currentColor"
          strokeWidth="2"
          d="M35.519 23.5c0 6.085-4.934 11.019-11.019 11.019S13.481 29.585 13.481 23.5m22.038 0c0-6.085-4.934-11.019-11.019-11.019S13.481 17.415 13.481 23.5m22.038 0H13.48M24.5 9.889V6M14.875 13.875l-2.75-2.75M34.125 13.875l2.75-2.75M38.111 23.5H42M24.5 41v-3.889M36.874 35.874l-2.75-2.75M12.126 35.874l2.75-2.75M7 23.5h3.889"
        ></path>
      </svg>
    </>
  )
}
