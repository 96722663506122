import Box from '@components/ui/layout/Box'
import { Text } from '@components/ui/typography'
import { urlFor } from '@lib/sanity/url-for'
import { motion } from 'framer-motion'
import React, { FC, useState } from 'react'
import { HomeSingleton } from '__generated__/sanity'
import { FadeInChild } from '../FadeInChild'
import { staggerAnimation, StaggerChildren } from '../StaggerChildren'
import { container, text, logosStyling } from './styles'

type Props = HomeSingleton['fields']['usedBy']

export const UsedBySection: FC<Props> = ({ usedByTitle, usedByBlocks }) => {
  const [startStagger, setStartStagger] = useState(false)
  return (
    <FadeInChild handleElementIsInView={() => setStartStagger(true)}>
      <Box className={container}>
        <Text variant="bodyLarge" color="shade400" className={text} mb="8">
          {usedByTitle}
        </Text>
        <StaggerChildren {...{ startStagger }} className={logosStyling}>
          {usedByBlocks.map(({ usedByBlockLogo }, i) => (
            <motion.img
              key={`usedByLogo${i}`}
              src={`${urlFor(usedByBlockLogo).url()}`}
              variants={staggerAnimation}
            />
          ))}
        </StaggerChildren>
      </Box>
    </FadeInChild>
  )
}
