import React, { FC } from 'react'
import { MotionValue, motion, useTransform } from 'framer-motion'
import { animationPoints } from '.'
import { enhancedImageParent, enhancedImageStyles, enhancementBorder } from './styles'

type EnhancedImageProps = {
  endPoint: number
  scrollY: MotionValue<number>
  image: string
}

export const EnhancedImage: FC<EnhancedImageProps> = ({ endPoint, scrollY, image }) => {
  const height = useTransform(
    scrollY,
    [animationPoints.enhancedImage.start * endPoint, animationPoints.enhancedImage.end * endPoint],
    ['calc(0% + 0px)', 'calc(100% + 0.25rem)']
  )

  return (
    <motion.div className={enhancedImageParent} style={{ height }}>
      <div
        className={enhancedImageStyles}
        style={{
          backgroundImage: `url(${image})`,
        }}
      />
      <div className={enhancementBorder} />
    </motion.div>
  )
}
