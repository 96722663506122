import React from 'react'
import { Heading, Text } from '@components/ui/typography'
import Box from '@components/ui/layout/Box'
import { avatar } from './styles'

export function Reviewer({
  reviewerAvatarUrl,
  reviewerFunction,
  reviewerName,
}: {
  reviewerAvatarUrl: string
  reviewerFunction: string
  reviewerName: string
}) {
  return (
    <Box display="flex" mt="auto" alignItems="center">
      <Box
        className={avatar}
        mr={{ _: '4', md: '6' }}
        style={{ background: `url(${reviewerAvatarUrl}) center/cover no-repeat` }}
      />
      <div>
        <Heading as="h6" variant="h6">
          {reviewerName}
        </Heading>
        <Text as="p" variant="bodySmall" color="shade500">
          {reviewerFunction}
        </Text>
      </div>
    </Box>
  )
}
