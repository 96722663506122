import React, { FC } from 'react'
import { hideOnDesktopS, showOnDesktopS } from './styles'

export const IENoiseReduction: FC = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="48"
        height="48"
        fill="none"
        viewBox="0 0 48 48"
        className={hideOnDesktopS}
      >
        <path
          stroke="currentColor"
          strokeWidth="2"
          d="M24 7L7 24l4.25 4.25 4.25 4.25 4.25 4.25L24 41m0-34l17 17-4.25 4.25-4.25 4.25-4.25 4.25L24 41m0-34v34m-9.563-12.75h1.594m4.782 0h-1.594m-1.063 4.25h1.063m3.01 0h-1.062M19.75 24h-2.48m-6.374 0h2.479m1.063-4.25h1.593m4.782 0h-1.594m-1.063-4.25h1.063m3.01 0h-1.062"
        ></path>
      </svg>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="48"
        height="48"
        fill="none"
        viewBox="0 0 48 48"
        className={showOnDesktopS}
      >
        <path
          stroke="currentColor"
          strokeWidth="2"
          d="M24 9L9 24l3.75 3.75 3.75 3.75 3.75 3.75L24 39m0-30l15 15-3.75 3.75-3.75 3.75-3.75 3.75L24 39m0-30v30m-8.438-11.25h1.407m4.218 0h-1.406m-.937 3.75h.937m2.657 0H21.5M20.25 24h-2.188m-5.625 0h2.188m.938-3.75h1.406m4.218 0h-1.406m-.937-3.75h.937m2.657 0H21.5"
        ></path>
      </svg>
    </>
  )
}
