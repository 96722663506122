import React, { FC } from 'react'
import { hideOnDesktopS, showOnDesktopS } from './styles'

export const IEResampling: FC = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="48"
        height="48"
        fill="none"
        viewBox="0 0 48 48"
        className={hideOnDesktopS}
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M7 38a3 3 0 003 3h5.5v-8.5H7V38z"
          clipRule="evenodd"
        ></path>
        <path fill="currentColor" d="M15.5 24H7v8.5h8.5V24zM15.5 41H24v-8.5h-8.5V41z"></path>
        <path
          stroke="currentColor"
          strokeWidth="2"
          d="M24 41h8.5M24 41h-8.5m8.5 0v-8.5M24 7h8.5M24 7h-8.5M24 7v8.5M41 24h-8.5m8.5 0v-8.5m0 8.5v8.5M7 24h8.5M7 24v-8.5M7 24v8.5M32.5 7H38a3 3 0 013 3v5.5M32.5 7v8.5m0 8.5v-8.5m0 8.5H24m8.5 0v8.5m0 8.5H38a3 3 0 003-3v-5.5M32.5 41v-8.5M15.5 7H10a3 3 0 00-3 3v5.5M15.5 7v8.5m0 8.5H24m-8.5 0v-8.5m0 8.5v8.5m0 8.5H10a3 3 0 01-3-3v-5.5m8.5 8.5v-8.5M7 15.5h8.5m25.5 0h-8.5M7 32.5h8.5m25.5 0h-8.5m0-17H24m0 0V24m0-8.5h-8.5M24 24v8.5m8.5 0H24m0 0h-8.5"
        ></path>
      </svg>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="48"
        height="48"
        fill="none"
        viewBox="0 0 48 48"
        className={showOnDesktopS}
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M11 34a3 3 0 003 3h3.5v-6.5H11V34z"
          clipRule="evenodd"
        ></path>
        <path fill="currentColor" d="M17.5 24H11v6.5h6.5V24zM17.5 37H24v-6.5h-6.5V37z"></path>
        <path
          stroke="currentColor"
          strokeWidth="2"
          d="M24 37h6.5M24 37h-6.5m6.5 0v-6.5M24 11h6.5M24 11h-6.5m6.5 0v6.5M37 24h-6.5m6.5 0v-6.5m0 6.5v6.5M11 24h6.5M11 24v-6.5m0 6.5v6.5M30.5 11H34a3 3 0 013 3v3.5M30.5 11v6.5m0 6.5v-6.5m0 6.5H24m6.5 0v6.5m0 6.5H34a3 3 0 003-3v-3.5M30.5 37v-6.5M17.5 11H14a3 3 0 00-3 3v3.5m6.5-6.5v6.5m0 6.5H24m-6.5 0v-6.5m0 6.5v6.5m0 6.5H14a3 3 0 01-3-3v-3.5m6.5 6.5v-6.5m-6.5-13h6.5m19.5 0h-6.5M11 30.5h6.5m19.5 0h-6.5m0-13H24m0 0V24m0-6.5h-6.5M24 24v6.5m6.5 0H24m0 0h-6.5"
        ></path>
      </svg>
    </>
  )
}
