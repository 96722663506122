import React, { FC, useState } from 'react'

import { FadeInChild } from '../FadeInChild'
import { Heading, Text } from '@components/ui/typography'
import { motion } from 'framer-motion'

import { Section } from '../Section'

import { Usp } from './Usp'
import { staggerAnimation, StaggerChildren } from '../StaggerChildren'
import { HomeSingleton } from '__generated__/sanity'
import { iconsContainer } from './styles'

type Props = HomeSingleton['fields']['usps']

export const Usps: FC<Props> = ({ uspTitle, uspSubtitle, uspIntro, uspBlocks }) => {
  const [startStagger, setStartStagger] = useState(false)

  return (
    <section id="explore">
      <FadeInChild handleElementIsInView={() => setStartStagger(true)}>
        <Section title={uspTitle} subtitle={uspSubtitle} introSection={uspIntro}>
          <StaggerChildren as="ul" className={iconsContainer} {...{ startStagger }}>
            {uspBlocks.map(({ uspBlockTitle, uspBlockDescription }, i) => (
              <motion.li key={uspBlockTitle} variants={staggerAnimation}>
                <Usp backgroundNr={i} iconNr={i}>
                  <Heading variant="h6" as="h6">
                    {uspBlockTitle}
                  </Heading>
                  <Text as="p" variant="bodySmall">
                    {uspBlockDescription}
                  </Text>
                </Usp>
              </motion.li>
            ))}
          </StaggerChildren>
        </Section>
      </FadeInChild>
    </section>
  )
}
