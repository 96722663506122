import { motion } from 'framer-motion'
import React, { FC, HTMLAttributes } from 'react'

type Props = HTMLAttributes<HTMLElement> & {
  as?: React.ElementType
  startStagger: boolean
}

export const animationContainer = {
  show: {
    transition: {
      delayChildren: 0.4,
      staggerChildren: 0.1,
    },
  },
}

export const staggerAnimation = {
  hidden: { opacity: 0, y: 15, rotate: 0.5 },
  show: { opacity: 1, y: 0, rotate: 0, transition: { ease: 'easeInOut', duration: 0.5 } },
}

export const StaggerChildren: FC<Props> = ({ children, as = 'ul', className, startStagger }) => {
  const Element = motion[`${as}`]

  return (
    <Element
      animate={startStagger ? 'show' : 'initial'}
      initial="hidden"
      variants={animationContainer}
      {...{ className }}
    >
      {children}
    </Element>
  )
}
