import React, { FC } from 'react'
import { hideOnDesktopS, showOnDesktopS } from './styles'

export const IEArtifactsRemoval: FC = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="48"
        height="48"
        fill="none"
        viewBox="0 0 48 48"
        className={hideOnDesktopS}
      >
        <path fill="currentColor" d="M24 15l-8.6 15.9h17.2L24 15z"></path>
        <path
          fill="currentColor"
          d="M15.4 30.9l-.88-.476-.798 1.476H15.4v-1zm17.2 0v1h1.678l-.798-1.476-.88.476zM18.553 15.65a1 1 0 000 2v-2zm.197 2a1 1 0 000-2v2zm-1.597.8a1 1 0 000 2v-2zm.197 2a1 1 0 000-2v2zm-1.597.8a1 1 0 000 2v-2zm.197 2a1 1 0 000-2v2zm-1.597.8a1 1 0 000 2v-2zm.197 2a1 1 0 000-2v2zm-1.597.8a1 1 0 000 2v-2zm.197 2a1 1 0 000-2v2zm-1.597.8a1 1 0 000 2v-2zm.197 2a1 1 0 000-2v2zm8.903 1.5a1 1 0 000 2v-2zm.197 2a1 1 0 000-2v2zm-3.697-2a1 1 0 000 2v-2zm.197 2a1 1 0 000-2v2zm-3.697-2a1 1 0 000 2v-2zm.197 2a1 1 0 000-2v2zM7 24v-9H5v9h2zm0-9V9H5v6h2zm34-6v6h2V9h-2zm0 6v9h2v-9h-2zM23 30.9V42h2V30.9h-2zM9 7h6V5H9v2zm15 0h9V5h-9v2zm9 0h6V5h-6v2zM7 33v-4.5H5V33h2zm0-4.5V24H5v4.5h2zM41 24v4.5h2V24h-2zm0 4.5V33h2v-4.5h-2zM7 39v-1.5H5V39h2zm0-1.5V33H5v4.5h2zM41 33v4.5h2V33h-2zm0 4.5V39h2v-1.5h-2zM15 41h-4.5v2H15v-2zm-4.5 0H9v2h1.5v-2zm9 0H15v2h4.5v-2zM33 41h-4.5v2H33v-2zm-4.5 0H24v2h4.5v-2zM39 41h-1.5v2H39v-2zm-1.5 0H33v2h4.5v-2zM15 7h3.375V5H15v2zm3.375 0H19.5V5h-1.125v2zm2.25 34H19.5v2h1.125v-2zM19.5 7h1.125V5H19.5v2zm5.5 8V6h-2v9h2zm-1.88-.476l-8.6 15.9 1.76.952 8.6-15.9-1.76-.952zM15.4 31.9h17.2v-2H15.4v2zm18.08-1.476l-8.6-15.9-1.76.952 8.6 15.9 1.76-.952zM15.4 31.9H24v-2h-8.6v2zm17.2-2H24v2h8.6v-2zM24 5h-3.375v2H24V5zm0 36h-3.375v2H24v-2zm-5.447-23.35h.197v-2h-.197v2zm-1.4 2.8h.197v-2h-.197v2zm-1.4 2.8h.197v-2h-.197v2zm-1.4 2.8h.197v-2h-.197v2zm-1.4 2.8h.197v-2h-.197v2zm-1.4 2.8h.197v-2h-.197v2zm9.1 3.5h.197v-2h-.197v2zm-3.5 0h.197v-2h-.197v2zm-3.5 0h.197v-2h-.197v2zM41 39a2 2 0 01-2 2v2a4 4 0 004-4h-2zm2-30a4 4 0 00-4-4v2a2 2 0 012 2h2zM7 9a2 2 0 012-2V5a4 4 0 00-4 4h2zM5 39a4 4 0 004 4v-2a2 2 0 01-2-2H5z"
        ></path>
      </svg>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="48"
        height="48"
        fill="none"
        viewBox="0 0 48 48"
        className={showOnDesktopS}
      >
        <path fill="currentColor" d="M24 16.5l-7.167 13.25h14.334L24 16.5z"></path>
        <path
          fill="currentColor"
          d="M16.833 29.75l-.88-.476-.797 1.476h1.677v-1zm14.334 0v1h1.677l-.798-1.476-.88.476zM19.46 16.875a1 1 0 100 2v-2zm.164 2a1 1 0 000-2v2zm-1.33.333a1 1 0 100 2v-2zm.163 2a1 1 0 100-2v2zm-1.33.334a1 1 0 000 2v-2zm.164 2a1 1 0 100-2v2zm-1.331.333a1 1 0 100 2v-2zm.164 2a1 1 0 000-2v2zm-1.33.333a1 1 0 100 2v-2zm.163 2a1 1 0 100-2v2zm-1.33.334a1 1 0 000 2v-2zm.164 2a1 1 0 100-2v2zm7.419.916a1 1 0 100 2v-2zm.164 2a1 1 0 100-2v2zm-3.08-2a1 1 0 100 2v-2zm.163 2a1 1 0 100-2v2zm-3.08-2a1 1 0 000 2v-2zm.164 2a1 1 0 100-2v2zM10 24v-7.5H8V24h2zm0-7.5V12H8v4.5h2zM38 12v4.5h2V12h-2zm0 4.5V24h2v-7.5h-2zM23 29.75V39h2v-9.25h-2zM12 10h4.5V8H12v2zm12 0h7.5V8H24v2zm7.5 0H36V8h-4.5v2zM10 31.5v-3.75H8v3.75h2zm0-3.75V24H8v3.75h2zM38 24v3.75h2V24h-2zm0 3.75v3.75h2v-3.75h-2zM10 36v-.75H8V36h2zm0-.75V31.5H8v3.75h2zm28-3.75v3.75h2V31.5h-2zm0 3.75V36h2v-.75h-2zM16.5 38h-3.75v2h3.75v-2zm-3.75 0H12v2h.75v-2zm7.5 0H16.5v2h3.75v-2zm11.25 0h-3.75v2h3.75v-2zm-3.75 0H24v2h3.75v-2zM36 38h-.75v2H36v-2zm-.75 0H31.5v2h3.75v-2zM16.5 10h2.813V8H16.5v2zm2.813 0h.937V8h-.938v2zm1.875 28h-.938v2h.938v-2zm-.938-28h.938V8h-.938v2zM25 16.5V9h-2v7.5h2zm-1.88-.476l-7.166 13.25 1.759.952 7.167-13.25-1.76-.952zM16.833 30.75h14.334v-2H16.833v2zm15.213-1.476l-7.166-13.25-1.76.952 7.167 13.25 1.76-.952zM16.833 30.75H24v-2h-7.167v2zm14.334-2H24v2h7.167v-2zM24 8h-2.813v2H24V8zm0 30h-2.813v2H24v-2zm-4.539-19.125h.164v-2h-.164v2zm-1.167 2.333h.164v-2h-.164v2zm-1.166 2.334h.164v-2h-.164v2zm-1.167 2.333h.164v-2h-.164v2zm-1.167 2.333h.164v-2h-.164v2zm-1.166 2.334h.164v-2h-.164v2zm7.583 2.916h.164v-2h-.164v2zm-2.917 0h.164v-2h-.164v2zm-2.916 0h.164v-2h-.164v2zM38 36a2 2 0 01-2 2v2a4 4 0 004-4h-2zm2-24a4 4 0 00-4-4v2a2 2 0 012 2h2zm-30 0a2 2 0 012-2V8a4 4 0 00-4 4h2zM8 36a4 4 0 004 4v-2a2 2 0 01-2-2H8z"
        ></path>
      </svg>
    </>
  )
}
