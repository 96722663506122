import { urlFor } from '@lib/sanity/url-for'
import { motion } from 'framer-motion'
import React, { Dispatch, FC, SetStateAction, useState } from 'react'
import { HomeSingleton } from '__generated__/sanity'
import { CtaBlock } from '../../cta-block'
import { FadeInChild } from '../FadeInChild'
import { staggerAnimation, StaggerChildren } from '../StaggerChildren'
import { container } from './styles'
import { Testimonial } from './Testimonial'

type Props = HomeSingleton['fields']['testimonials'] & {
  setShowOverlay: Dispatch<SetStateAction<boolean>>
}

export const TestimonialsSection: FC<Props> = ({
  setShowOverlay,
  testimonialBlocks,
  testimonialCtaText,
}) => {
  const [startStagger, setStartStagger] = useState(false)
  return (
    <FadeInChild handleElementIsInView={() => setStartStagger(true)}>
      <StaggerChildren className={container} {...{ startStagger }}>
        {testimonialBlocks.map((item, i) => (
          <motion.li key={`testimonial${i}`} variants={staggerAnimation}>
            <Testimonial
              logoUrl={urlFor(item.testimonialLogo).url()}
              text={item.testimonialContent}
              reviewerAvatarUrl={urlFor(item.testimonialAuthorImage).url()}
              reviewerName={item.testimonialAuthorName}
              reviewerFunction={item.testimonialAuthorFunction}
            />
          </motion.li>
        ))}
      </StaggerChildren>
      <CtaBlock title={testimonialCtaText} handleClick={() => setShowOverlay((x) => !x)} />
    </FadeInChild>
  )
}
