import React, { FC } from 'react'
import { Feature } from './Feature'
import { MotionValue } from 'framer-motion'
import { animationPoints } from '..'
import {
  IEAiObjectDetection,
  IEColorCorrection,
  IESharpnessCorrection,
  IEArtifactsRemoval,
  IEUpscaling,
} from '../Icons'
import { parent } from './styles'

type FeatureType = {
  icon: React.FC
  title: string
}

export const items: FeatureType[] = [
  {
    icon: IEAiObjectDetection,
    title: 'AI Image Analysis',
  },
  {
    icon: IEArtifactsRemoval,
    title: 'AI Artifacts Removal',
  },
  {
    icon: IEUpscaling,
    title: 'AI Upscaling',
  },
  {
    icon: IEColorCorrection,
    title: 'Global Corrections',
  },
  {
    icon: IESharpnessCorrection,
    title: 'Local Corrections',
  },
]

type FeaturesProps = {
  scrollY: MotionValue<number>
  endPoint: number
}

export const Features: FC<FeaturesProps> = ({ scrollY, endPoint }) => {
  return (
    <div className={parent}>
      {items.map((item, key) => {
        const increment = 0.07 * key
        const start = (animationPoints.features.start + increment) * endPoint
        const end = (animationPoints.features.end + increment) * endPoint
        const Icon = item.icon
        return (
          <Feature key={key} scrollY={scrollY} range={[start, end]} title={item.title}>
            <Icon />
          </Feature>
        )
      })}
    </div>
  )
}
