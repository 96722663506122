import { Image } from '@components/ui/image'
import { MotionValue } from 'framer-motion'
import React, { FC, useCallback, useEffect, useRef, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import { Sliding } from './Sliding'
import { container, parent } from './styles'

type SliderProps = {
  scrollY: MotionValue<number>
  oldPlaceholder: string
  newPlaceholder: string
  oldSources: string[]
  newSources: string[]
  ratio: number
}

export const Slider: FC<SliderProps> = ({
  scrollY,
  oldPlaceholder,
  newPlaceholder,
  oldSources,
  newSources,
  ratio,
}) => {
  const [start, setStart] = useState(null)
  const [end, setEnd] = useState(null)
  const width = useRef<number>()
  const [isReady, setIsReady] = useState(false)
  const [inViewRef, inView] = useInView({
    triggerOnce: true,
    threshold: 0.2,
  })
  const ref = useRef<HTMLDivElement>()

  useEffect(() => {
    calculate()

    window.addEventListener('resize', calculate)

    return () => {
      window.removeEventListener('resize', calculate)
    }

    function calculate() {
      if (window.innerWidth !== width.current) {
        setIsReady(false)
        setStart(
          ref.current.getBoundingClientRect().top + window.pageYOffset - window.innerHeight * 0.9
        )
        setEnd(
          ref.current.getBoundingClientRect().top +
            ref.current.getBoundingClientRect().height +
            window.pageYOffset -
            window.innerHeight +
            window.innerHeight * 0.1
        )
        setIsReady(true)
        width.current = window.innerWidth
      }
    }
  }, [inView])

  const setRefs = useCallback(
    (node) => {
      ref.current = node
      inViewRef(node)
    },
    [inViewRef]
  )

  return (
    <div
      className={parent}
      style={{ '--ratio': `${ratio * 100}%` } as React.CSSProperties}
      data-visible={inView ? 'visible' : 'hidden'}
      ref={setRefs}
    >
      <div className={container} ref={ref}>
        <Image
          alt=""
          placeholderSrc={oldPlaceholder}
          sources={oldSources}
          height={['100%']}
          width={['100%']}
          position="absolute"
          objectPosition="top"
        />
        {isReady && start && end && (
          <Sliding
            scrollY={scrollY}
            start={start}
            end={end}
            sources={newSources}
            placeholder={newPlaceholder}
          />
        )}
      </div>
    </div>
  )
}
