import { theme } from '@config/theme'
import { useBreakpoint } from '@lib/hooks'
import { ComparisonSlider } from '@pages/index'
import { useViewportScroll } from 'framer-motion'
import React, { FC } from 'react'
import { Slider } from './Slider'
import { column, container, parent } from './styles'

type ComparisonSlidersProps = {
  items: ComparisonSlider[]
}

export const ComparisonSliders: FC<ComparisonSlidersProps> = ({ items }) => {
  const { scrollY } = useViewportScroll()
  const isTabletUp = useBreakpoint(theme.breakpoints.sm)
  const columns = isTabletUp
    ? [items.slice(0, 2), items.slice(2, 4), items.slice(4, 6)]
    : [items.slice(0, 3), items.slice(3, 6)]

  return (
    <div className={container}>
      <div className={parent}>
        {columns.map((block, key) => (
          <div
            className={column}
            key={`${key}-${isTabletUp ? 'desktop' : 'mobile'}`}
            data-variant={key === 1 ? 'padded' : 'regular'}
          >
            {block.map((slider, key) => {
              return (
                <Slider
                  key={key}
                  scrollY={scrollY}
                  oldPlaceholder={slider.nonEnhanced.url}
                  newPlaceholder={slider.enhanced.url}
                  oldSources={[slider.nonEnhanced.url]}
                  newSources={[slider.enhanced.url]}
                  ratio={
                    slider.enhanced.metadata.dimensions.height /
                    slider.enhanced.metadata.dimensions.width
                  }
                />
              )
            })}
          </div>
        ))}
      </div>
    </div>
  )
}
