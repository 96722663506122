import Box from '@components/ui/layout/Box'
import { Text } from '@components/ui/typography'
import React from 'react'
import { Reviewer } from './Reviewer'
import { divider, image, imageContainer, testimonial } from './styles'

export function Testimonial({
  logoUrl,
  text,
  reviewerAvatarUrl,
  reviewerName,
  reviewerFunction,
}: {
  logoUrl: string
  text: string
  reviewerAvatarUrl: string
  reviewerName: string
  reviewerFunction: string
}) {
  return (
    <div className={testimonial}>
      <Box className={imageContainer}>
        <img className={image} src={logoUrl} alt="" />
      </Box>
      <Text variant="bodyRegular" as="h2" color="shade500" my={{ _: '6', md: '8' }} mb="auto">
        {text}
      </Text>
      <Reviewer {...{ reviewerAvatarUrl, reviewerName, reviewerFunction }} />
      <hr className={divider} />
    </div>
  )
}
